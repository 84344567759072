import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLinkedinIn,
  faInstagram,
  faGithubAlt,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { faGraduationCap } from "@fortawesome/free-solid-svg-icons";
import { COLORS_LIGHT } from "../custom/Values";
import { BasicInfo } from "../content/BasicInfo";

export const Socials = () => (
  <div
    style={{
      // border: "1px solid green",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      // paddingRight: "60px",
    }}
  >
    <SocialItem href={BasicInfo.linkedInLink} target="_blank">
      <FontAwesomeIcon icon={faLinkedinIn} size="1x" />
    </SocialItem>
    <SocialItem href={BasicInfo.githubLink} target="_blank">
      <FontAwesomeIcon icon={faGithubAlt} size="1x" />
    </SocialItem>
    <SocialItem href={BasicInfo.googleScholarLink} target="_blank">
      <FontAwesomeIcon icon={faGraduationCap} size="1x" />
    </SocialItem>
    <SocialItem href={"mailto:" + BasicInfo.email} target="_blank">
      <FontAwesomeIcon icon={faEnvelope} size="1x" />
    </SocialItem>
    {/* <SocialItem href={BasicInfo.instagram} target="_blank">
      <FontAwesomeIcon icon={faInstagram} size="1x" />
    </SocialItem> */}
  </div>
);

const SocialItem = styled.a`
  // border: 1px solid green;
  width: 40px;
  height: 40px;
  margin: 0px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${COLORS_LIGHT.LIGHT_GRAY};
  cursor: pointer;
  &: hover {
    color: ${COLORS_LIGHT.ACCENT};
  }
`;
