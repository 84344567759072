import styled from "styled-components";
import { UnderConstruction } from "../components/UnderConstruction";
import { CustomParagraph, CustomTitle } from "../custom/CustomText";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileInvoice,
  faQuoteRight,
  faFilePdf,
  faAward,
} from "@fortawesome/free-solid-svg-icons";
import { COLORS_LIGHT } from "../custom/Values";
import { PublicationList } from "../components/PublicationList";
import { Posters, TalksList } from "../components/TalkList";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack5";
import { BasicInfo } from "../content/BasicInfo";
export const ResearchPage = () => {
  return (
    <>
      <TopSection>
        <ResearchStatementContainer>
          <CustomParagraph
            display="full"
            limit={800}
            title={"MY RESEARCH"}
            body={BasicInfo.research.researchBio}
          />
        </ResearchStatementContainer>
        <PetsImgContainer>
          <PetsImg src={BasicInfo.research.researchImageUrl} />
        </PetsImgContainer>
        {/* <UnderConstruction /> */}
      </TopSection>
      <PubSection>
        <CustomTitle title={"PUBLICATIONS"} />
        <PubContainer>
          {/* <CustomTitle title={"UNDER REVIEW"} style="light" /> */}
          <PublicationList
            publicationRecord={BasicInfo.research.publications}
          />
        </PubContainer>
        {/* <PubContainer>

                    <CustomTitle title={"PUBLISHED"} style="light" />
                    <PublicationList publicationRecord={submittedPublication} />
                </PubContainer> */}
      </PubSection>
      <MaterialsSection>
        <CustomTitle title={"PRESENTATIONS & TUTORIALS"} />
        <PresentationContainer>
          <TalksList talks={BasicInfo.research.presentations} />
        </PresentationContainer>
        <CustomTitle title={"POSTERS"} />
        <PostersContainer>
          <Posters posters={BasicInfo.research.posters} />
        </PostersContainer>
      </MaterialsSection>
      <ResumeSection>
        <CustomTitle title={"CURRICULUM VITTAE"} />

        <ResumeFrame src={BasicInfo.cvLink} />
      </ResumeSection>
    </>
  );
};

const TopSection = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: start;
  // padding-top: 45px;
  // border: 1px solid green;

  @media (max-width: 1024px) {
    // background-color: red;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-around;
    align-items: center;
  }
`;

const ResearchStatementContainer = styled.div`
  // border: 1px solid red;
  display: flex;
  justify-content: start;
  align-items: center;
  width: 40%;
  margin-top: 80px;
  padding: 5px 20px;
  @media (max-width: 1024px) {
    // background-color:green;
    // padding: 5px 20px;
    align-items: center;
    margin-top: 25px;
    width: 90%;
  }
`;

const PetsImgContainer = styled.div`
  // border: 1px solid blue;
  display: flex;
  justify-content: start;
  align-items: center;
  @media (max-width: 1024px) {
    // background-color:blue;
    width: 100%;
    justify-content: center;
  }
`;

const PetsImg = styled.img`
  width: 600px;
  max-width: 100%;
  @media (max-width: 1024px) {
  }
`;
const PubSection = styled.div({
  display: "flex",
  flexDirection: "column",
  justifyContent: "start",
  alignItems: "center",
  paddingTop: "45px",
});

const PubContainer = styled.div({
  // border: "1px solid red",
  display: "flex",
  flexDirection: "column",
  justifyContent: "start",
  width: "95%",
  // padding: "0px 10px",
});

const MaterialsSection = styled.div({
  backgroundColor: COLORS_LIGHT.SECONDARY_BG,
  display: "flex",
  flexDirection: "column",
  justifyContent: "start",
  alignItems: "center",
  paddingTop: "35px",
});

const PresentationContainer = styled.div({
  // border: "1px solid red",
  display: "flex",
  flexDirection: "column",
  justifyContent: "start",
  width: "95%",
  padding: "0px 10px",
  marginBottom: "30px",
});

const PostersContainer = styled.div({
  // border: "1px solid red",
  display: "flex",
  flexDirection: "column",
  justifyContent: "start",
  width: "95%",
  // padding: "0px 10px",
  marginBottom: "30px",
});
const ResumeSection = styled.div`
  // border: 1px solid red;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  padding: 5px;
  @media (max-width: 1024px) {
    // background-color: green;
    // align-items: center;
    // margin-top: 25px;
    // width: 100%;
  }
`;
const ResumeFrame = styled.iframe`
  // border: 1px solid blue;
  width: 840px;
  height: 640px;
  max-width: 100%;
  @media (max-width: 1024px) {
  }
`;
