export const BasicInfo = {
  fullName: "BINIYAM M. TIRUYE",
  photoUrl:
    "https://raw.githubusercontent.com/biniyam-mt/website/main/portrait.png",
  title: "Phd Candidate,",
  department: "Computer Science",
  university: "University of Michigan",
  addressRoom: "2637 Beyster Bldg",
  addressStreet: "2260 Hayward Avenue ",
  addressZip: "Ann Arbor, MI 48109-2121",
  email: "btiruye@umich.edu",
  cvLink:
    "https://drive.google.com/file/d/1I-nttIX5uUdnX--Q9hwQgCs8gQcu6iS2/preview",
  googleScholarLink:
    "https://scholar.google.com/citations?user=REa5JCAAAAAJ&hl=en",
  linkedInLink: "https://www.linkedin.com/in/biniyam-mengist/",
  twitterLink: "",
  githubLink: "https://github.com/biniyam-mt",
  youTubeLink: "",
  instagram: "https://www.instagram.com/biniyam_mt/",
  personalBiography:
    "I am an ambitious and dedicated Computer Science graduate student specializing in privacy and security research, I am advised by Professor Todd Austin. Hailing from Ethiopia as a first-generation student, I am driven by a deep desire to bring about positive change and improve the lives of people. With a relentless pursuit of knowledge and a passion for academic excellence, I am actively establishing myself in the security research scene. My goal is to contribute innovative solutions to enhance privacy protection and strengthen security measures.",
  contentLocation:
    "https://raw.githubusercontent.com/biniyam-mt/content/main/news.json",
  edu: [
    {
      logoUrl:
        "https://raw.githubusercontent.com/biniyam-mt/website/main/umich-logo.jpg",
      institute: "University of Michigan",
      title: "Doctor of Philosophy - PhD, Computer Science",
      duration: "Aug 2021 - Jun 2026",
      grade: "Grade: 4.0",
    },
    {
      logoUrl:
        "https://raw.githubusercontent.com/biniyam-mt/website/main/aau-logo.jpg",
      institute: "Addis Ababa University",
      title: "Bachelor's degree, Software Engineering",
      duration: "Sep 2015 - Dec 2020",
      grade: "Grade: 3.82",
    },
  ],
  research: {
    researchBio:
      "I work with PETs, no not those PETs, Privacy Enhanced Technologies. Specifically, I am Interested in finding ways to embed security and privacy at the heart of the computing systems of tomorrow by leveraging advancements in both software and hardware technology. My current research focuses on building tools to automate the design and implementation of algorithms to secure them against digital side-channel attacks and to maintain the secrecy of private data.",
    researchImageUrl:
      "https://raw.githubusercontent.com/biniyam-mt/website/main/pets.png",
    publications: [
      {
        title: "Exploring the Efficiency of Data-Oblivious Programs",
        conference:
          "2023 IEEE International Symposium on Performance Analysis of Systems and Software",
        publisher: "IEEE",
        year: 2023,
        authors: [
          "Lauren Biernacki",
          "Biniyam Tiruye",
          "Meron Demissie",
          "Fitsum Andargie",
          "Brandon Reagen",
          "Todd Austin",
        ],
        citations: 0,
        link: "",
        awards: [],
      },
    ],
    presentations: [],
    posters: [
      {
        title: "OCTAL: DSL for Data-Oblivious programming",
        author: "Biniyam Tiruye",
        description: "S&P 2023",
        url: "https://drive.google.com/file/d/1Sdav0HEoIwoxPDE4Fkz5PteKbHtmnHhw/view?usp=share_link",
        coverImage:
          "https://raw.githubusercontent.com/biniyam-mt/content/main/image/octal-poster.png",
      },
      {
        title: "Simulating Devices on the Morpheus Secure Processor",
        author: "Biniyam Tiruye",
        description: "Aura 2019",
        url: "https://cse.umich.edu/2022visitday-abresearch/posters/Poster-Biniyam.pdf",
        coverImage:
          "https://raw.githubusercontent.com/biniyam-mt/website/main/poster1.png",
      },
    ],
  },
};
