import './App.css';
import { Root } from './main/Root'

function App() {
  return (
    <Root />
  );
}

export default App;
