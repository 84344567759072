import styled from "styled-components";
import { UnderConstruction } from "../components/UnderConstruction";
import { CustomParagraph, CustomTitle } from "../custom/CustomText";
import { useState, useEffect } from "react";
import { COLORS_LIGHT, FONT, TEXT } from "../custom/Values";
import { Section, Col } from "../custom/Elements";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAt,
  faComputerMouse,
  faCopy,
  faLocationDot,
  faMouse,
} from "@fortawesome/free-solid-svg-icons";
import { faClipboard } from "@fortawesome/free-regular-svg-icons";
import { News } from "../components/TalkList";
import { Shapes } from "../components/Shapes";
import { Socials } from "../components/Socials";
import { BasicInfo } from "../content/BasicInfo";
import news from "../content/news.json";

export const HomePage = () => {
  const [newsContent, setNewsContent] = useState([]);

  useEffect(() => {
    fetch(BasicInfo.contentLocation)
      .then((res) => res.json())
      .then((json) => {
        console.log("raw: ", json);
        setNewsContent(json);
      });
  }, []);

  return (
    <>
      <HomeSection>
        <LeftSection>
          <MyName>{BasicInfo.fullName}</MyName>
          <MyTitle>
            <strong>{BasicInfo.title}</strong> {BasicInfo.department}
          </MyTitle>
          <MyCampus>{BasicInfo.university} </MyCampus>
          <MyCampus>
            Advised by <strong>Professor Todd Austin</strong>
          </MyCampus>

          <MyEmail>
            <InfoIcons>
              <FontAwesomeIcon
                icon={faAt}
                size="lg"
                color={COLORS_LIGHT.ACCENT}
              />
            </InfoIcons>
            <InfoText>{BasicInfo.email}</InfoText>
          </MyEmail>
        </LeftSection>
        <RightSection>
          <HomeImageContainer>
            <ContourContainer>
              <img
                marginTop="-10px"
                class="contour"
                src="https://raw.githubusercontent.com/biniyam-mt/website/main/contour2.png"
                height={"115%"}
              />
            </ContourContainer>
            <ShapesContainer>
              <Shapes />
            </ShapesContainer>

            <HeadshotContainer>
              <Headshot
                src={BasicInfo.photoUrl}
                // src={"./new_bage.png"}
              />
            </HeadshotContainer>
          </HomeImageContainer>
        </RightSection>
      </HomeSection>
      <Section>
        <ScrollDown>
          <div class="box bounce-5">
            <div>
              <svg
                class="bi bi-mouse"
                fill="currentColor"
                height="16"
                viewBox="0 0 16 16"
                width="26"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M8 3a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 3zm4 8a4 4 0 0 1-8 0V5a4 4 0 1 1 8 0v6zM8 0a5 5 0 0 0-5 5v6a5 5 0 0 0 10 0V5a5 5 0 0 0-5-5z" />
              </svg>
            </div>
            <div>Scroll Down</div>
          </div>
        </ScrollDown>
      </Section>
      <AboutSection align={true} bg2={true}>
        <BioContainer>
          <CustomParagraph
            display="full"
            limit={800}
            title={"BIO"}
            body={BasicInfo.personalBiography}
          />
        </BioContainer>
        <EducationContainer>
          <CustomTitle title={"EDUCATION"} />
          {BasicInfo.edu.map((ed) => (
            <Education edu={ed} />
          ))}
        </EducationContainer>
      </AboutSection>
      <S3>
        <CustomTitle title={"RECENT NEWS"} />
        <S3R1>
          <News news={newsContent} />
        </S3R1>
      </S3>
    </>
  );
};

const Education = ({ edu }) => {
  return (
    <EduContainer>
      <EduLogo>
        <ImageContainer>
          <img src={edu.logoUrl} width={"100%"} />
        </ImageContainer>
      </EduLogo>
      <EduText>
        <EduCampus>{edu.institute}</EduCampus>
        <EduTitle>{edu.title}</EduTitle>
        <EduDate>{edu.duration}</EduDate>
        <EduGrade>{edu.grade}</EduGrade>
      </EduText>
    </EduContainer>
  );
};

export const HomeSection = styled.div`
  // max-height:950px;
  // box-sizing: border-box;
  // border: 1px solid green;
  display: flex;
  justify-content: center;
  align-items: ${(props) => (props.align ? "start" : "center")};
  padding-top: 45px;
  // width: 100%;
  margin-bottom: 5px;
  background-color: ${(props) => (props.bg2 ? COLORS_LIGHT.SECONDARY_BG : "")};

  @media (max-width: 1024px) {
    // background-color:red;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-around;
    align-items: center;
  }
  @media (min-width: 1024px) {
    height: calc(100vh - 180px);
  }
`;

const LeftSection = styled.div`
  // border: 1px solid red;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  width: 100%;
  font-family: Montserrat;
  margin: 0px 25px;
  @media (max-width: 1024px) {
    // background-color:green;
    align-items: center;
    margin-top: 25px;
  }
`;

const RightSection = styled.div`
  // border: 1px solid blue;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1024px) {
    // background-color:blue;
  }
`;
const ImageContainer = styled.div({
  position: "relative",
});

const HomeImageContainer = styled.div`
  position: relative;
  // border: 1px solid purple;
  width: 700px;
  height: 700px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1024px) {
    width: 350px;
    height: 350px;
  }
`;

const ContourContainer = styled.div`
  // border: 1px solid red;
  width: 90%;
  height: 90%;
  border-radius: 50%;
  background-color: #e1d19b;
  display: block;
  overflow: hidden;

  @media (max-width: 1024px) {
    //  width: 300px;
    // height: 300px;
  }
`;

const HeadshotContainer = styled.div`
  // border: 1px solid red;
  width: 100%;
  height: 100%;
  // border-radius: 50%;
  position: absolute;
  top: 0;
  @media (max-width: 1024px) {
    // width: 300px;
    // height: 300px;
  }
`;
const Headshot = styled.img`
  // border: 1px solid blue;
  width: 100%;
  height: 100%;
  @media (max-width: 1024px) {
  }
`;

const ShapesContainer = styled.div`
  // border: 1px solid red;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const AboutSection = styled.div`
  // border: 1px solid green;
  display: flex;
  justify-content: space-around;
  align-items: ${(props) => (props.align ? "start" : "center")};
  padding-top: 45px;
  padding-bottom: 10px;
  width: 100%;
  margin-bottom: 5px;
  background-color: ${(props) => (props.bg2 ? COLORS_LIGHT.SECONDARY_BG : "")};
  @media (max-width: 1024px) {
    // background-color:red;
    flex-direction: column;
    // justify-content: space-around;
    align-items: center;
    padding-top: 25px;
  }
`;
const BioContainer = styled.div`
  // border: 1px solid green;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  // width: 100%;
  font-family: Montserrat;
  margin: 0px 0px;
  @media (max-width: 1024px) {
    // background-color:red;
    justify-content: center;
    align-items: center;
    padding: 15px;
    margin-bottom: 10px;
  }
  @media (min-width: 1024px) {
    margin: 0px 45px;
    width: 100%;
  }
`;

const EducationContainer = styled.div`
  // border: 1px solid green;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  font-family: Montserrat;
  @media (max-width: 1024px) {
    // background-color:red;
    justify-content: center;
    align-items: center;
    padding: 15px;
    margin-bottom: 10px;
  }
  @media (min-width: 1024px) {
    margin: 0px 45px;
    width: 100%;
  }
`;

const EduContainer = styled.div({
  // border: "1px solid green",
  display: "flex",
  justifyContent: "start",
  alignItems: "start",
  marginBottom: "15px",
});
const EduLogo = styled.div({
  // border: "1px solid green",
  width: 40,
  height: 40,
  margin: "0px 15px 10px 10px",
});

const EduText = styled.div({
  // border: "1px solid green",
  fontFamily: TEXT.FONT,
});

const EduCampus = styled.div({
  // border: "1px solid red",
  color: COLORS_LIGHT.BLACK,
  fontSize: TEXT.SIZE_3,
  fontWeight: "bold",
  marginBottom: 2,
});

const EduTitle = styled.div({
  // border: "1px solid green",
  color: COLORS_LIGHT.BLACK,
  marginBottom: 2,
  fontSize: TEXT.SIZE_2,
});
const EduDate = styled.div({
  // border: "1px solid red",
  color: COLORS_LIGHT.MEDIUM_GRAY,
  fontSize: TEXT.SIZE_1,
  marginBottom: 5,
});

const EduGrade = styled.div({
  // border: "1px solid green",
  color: COLORS_LIGHT.BLACK,
  fontSize: TEXT.SIZE_2,
  fontWeight: "bold",
  marginBottom: 2,
});

const MyName = styled.div`
  font-size: ${TEXT.SIZE_7};
  margin-bottom: 15px;

  @media (max-width: 1024px) {
    font-size: ${TEXT.SIZE_5};
  }
`;

const MyTitle = styled.div`
  color: ${COLORS_LIGHT.BLACK};
  margin-bottom: 5px;
  @media (max-width: 1024px) {
    font-size: ${TEXT.SIZE_2};
    text-align: center;
  }
`;
const MyCampus = styled.div`
  color: ${COLORS_LIGHT.MEDIUM_GRAY};
  margin-bottom: 15px;
  @media (max-width: 1024px) {
    font-size: ${TEXT.SIZE_2};
    text-align: center;
  }
`;

const MyLocation = styled.div({
  // border: "1px solid red",
  width: "100%",
  color: COLORS_LIGHT.DARK_GRAY,
  marginBottom: "10px",
  display: "flex",
  justifyContent: "start",
  fontSize: TEXT.SIZE_1,
  // alignItems: "center",
});

const MyEmail = styled.div({
  color: COLORS_LIGHT.MEDIUM_GRAY,
  marginBottom: "5px",
  display: "flex",
  justifyContent: "start",
  fontSize: TEXT.SIZE_1,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const InfoIcons = styled.div({
  // border: "1px solid red",
  width: "25px",
});
const CopyIcon = styled.div({
  // border: "1px solid " + COLORS_LIGHT.LIGHT_GRAY,
  width: "20px",
  height: "20px",
  marginLeft: "20px",
  borderRadius: "15%",
  padding: "3px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  boxShadow: "0px 1px 3px " + COLORS_LIGHT.LIGHT_GRAY,
});
const InfoText = styled.span({
  // border: "1px solid red",
  // width: "9px",
});

const S1C2 = styled.div({
  // border: "1px solid red",
  display: "flex",
  justifyContent: "start",
  alignItems: "center",
});

const ScrollDown = styled.div({
  // border: "1px solid red",
  width: "100%",
  // alignSelf: "start",
  color: COLORS_LIGHT.ACCENT,
  fontFamily: TEXT.FONT,
  fontSize: TEXT.SIZE_2,
  marginBottom: "20px",
  display: "flex",
  alignItems: "end",
  justifyContent: "space-between",
});

const S3 = styled.div({
  display: "flex",
  flexDirection: "column",
  justifyContent: "start",
  alignItems: "center",
  paddingTop: "35px",
});

const S4 = styled.div({
  display: "flex",
  flexDirection: "column",
  justifyContent: "start",
  alignItems: "center",
  paddingTop: "35px",
});

const S2R1 = styled.div({
  // border: "1px solid red",
  display: "flex",
  flexDirection: "column",
  justifyContent: "start",
  width: "95%",
  padding: "0px 10px",
});

const S3R1 = styled.div({
  display: "flex",
  flexDirection: "column",
  justifyContent: "start",
  width: "95%",
  padding: "0px 10px",
  marginBottom: "30px",
});
