import { useEffect, useState } from "react";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import { COLORS_LIGHT } from "../custom/Values";
import basicInfo from "../content/BasicInfo";
import Toggle from "react-toggle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAt,
  faCloudMoon,
  faLocationDot,
  faPhone,
  faUserGraduates,
} from "@fortawesome/free-solid-svg-icons";
import { faMoon, faSun } from "@fortawesome/free-regular-svg-icons";
import { Socials } from "./Socials";
export const Nav = ({ tabs }) => {
  let currentPath = useLocation().pathname;
  currentPath = currentPath == "/" ? "/home" : currentPath;
  const [navBarColor, setNavBarColor] = useState("white");
  const [isDark, setIsDark] = useState(false);

  const listenScrollEvent = (event) => {
    if (window.scrollY === 0) {
      return setNavBarColor("transparent");
    } else {
      return setNavBarColor(COLORS_LIGHT.SECONDARY_BG);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);

    return () => window.removeEventListener("scroll", listenScrollEvent);
  }, []);
  return (
    <NavContainer style={{ backgroundColor: navBarColor }}>
      <Inner>
        <NavItemsContainer>
          {tabs.map((tab) => {
            const tabLower = tab.toLowerCase();
            return (
              <StyledLink to={"/" + tabLower}>
                <NavItem active={currentPath === "/" + tabLower}>
                  <NavBeacon active={currentPath === "/" + tabLower} />
                  <NavText>{tab}</NavText>
                </NavItem>
              </StyledLink>
            );
          })}
        </NavItemsContainer>
        <Socials />
        {/* <DarkModeContainer>
        {isDark && (
          <ThemeIcons>
          <FontAwesomeIcon
          icon={faCloudMoon}
          size="s"
          color={COLORS_LIGHT.ACCENT}
          />
          </ThemeIcons>
          )}
          {!isDark && (
            <ThemeIcons>
            <FontAwesomeIcon
            icon={faSun}
            size="s"
            color={COLORS_LIGHT.ACCENT}
            />
            </ThemeIcons>
            )}
            <Toggle
            defaultChecked={false}
            className="custom"
            icons={{
              checked: null,
              unchecked: null,
            }}
            onChange={() => {
              setIsDark(!isDark);
            }}
            />
          </DarkModeContainer> */}
      </Inner>
    </NavContainer>
  );
};

const NavContainer = styled.div`
  // border: 1px solid black;
  display: flex;
  justify-content: center;
  position: fixed;
  right: 0;
  left: 0;
  z-index: 1000;
  font-family: Montserrat;
`;
const Inner = styled.div({
  // border: "1px solid red",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  height: 50,
  maxWidth: 1400,
  width: 1400,
  padding: "0px 0px",
});

const TitleContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "end",
  cursor: "pointer",
});
const Title = styled.div({
  // border: "1px solid red",
  fontSize: "115%",
  fontWeight: 600,
});
const Beacon = styled("div")`
  background-color: ${(props) =>
    props.active ? COLORS_LIGHT.ACCENT : "transparent"};
  height: 5px;
  width: 15px;
`;
const NavItemsContainer = styled.div({
  // border: "1px solid red",
  display: "flex",
  flexDirection: "row",
  justifyContent: "start",
  width: "50%",
  // fontWeight: "bold"
});

const NavItem = styled("div")`
  // border: 1px solid green;
  height: 33px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 0px 20px;
  padding-bottom: 15px;
  // font-family: Montserrat  ${(props) => (props.active ? "" : "")};
  cursor: pointer;
  font-size: 85%;
  font-weight: ${(props) => (props.active ? "Bold" : "")};
  color: ${(props) =>
    props.active ? COLORS_LIGHT.BLACK : COLORS_LIGHT.DARK_GRAY};
  &: hover {
    color: ${COLORS_LIGHT.ACCENT};
  }
`;

const NavBeacon = styled("div")`
  background-color: ${(props) =>
    props.active ? COLORS_LIGHT.ACCENT : "transparent"};
  height: 3px;
  width: 20px;
`;

const DarkModeContainer = styled.div({
  // border: "1px solid red",
  width: "50px",
  fontWeight: 500,
  fontSize: "85%",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "start",
  paddingRight: "10px",
});

const NavText = styled.span({
  // border: "1px solid red",
  textDecoration: "none",
});

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const ThemeModeText = styled.span({
  // border: "1px solid red",
  width: 80,
  marginLeft: 2,
});

const ThemeIcons = styled.div({
  // border: "1px solid red",
  margin: 5,
});
